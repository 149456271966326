import HomePage from '@components/HomePage'
import { useTranslate } from '@xFrame4/common/Hooks'
import type { NextPage } from 'next'
import Head from 'next/head'

const Home: NextPage = () =>
{
    const t = useTranslate();

    /** Render. */
    return (
        <>
            <Head>
                <title>{t('APP_TITLE')}</title>
                <meta name="description" content={t('META_DESCRIPTION')}></meta>
            </Head>

            <HomePage />
        </>
    );
}

export default Home;